<template>
    <div class="archiveWrapper">
        <div class="grid-row-widgets">
            <div class="widget">
                <div>
                    <span class="widget-title">{{ $t("Lowest Score") }}</span>
                    <span class="widget-info">{{ this.minScore }}%</span>
                </div>
                <img loading="lazy" decoding="async" src="@/assets/Images/icons/bar-graph.svg" alt="bar-graph" />
            </div>
            <div class="widget-2">
                <img loading="lazy" decoding="async" src="@/assets/Images/icons/bar-chart-icon.svg" alt="bar-chart-icon" />
                <div>
                    <span class="widget-title">{{ $t("Average Time") }}</span>
                    <span class="widget-info">{{ calculateAverageTime() }} </span>
                </div>
            </div>
            <div class="widget background-1">
                <div>
                    <span class="widget-title">{{ $t("Pass Rate") }}</span>
                    <span class="widget-info">{{ getSuccessRate() }} %</span>
                </div>
                <div>
                    <img loading="lazy" decoding="async" src="@/assets/Images/icons/widget-chart.svg" alt="widget-chart" />
                    <!-- <span class="note good">{{ $t("Good") }}</span> -->
                </div>
            </div>
            <div class="widget background-2">
                <div>
                    <span class="widget-title">{{ $t("Best Score") }}</span>
                    <span class="widget-info">{{ this.maxScore }}%</span>
                </div>
                <div>
                    <img loading="lazy" decoding="async" src="@/assets/Images/icons/widget-chart.svg" alt="widget-chart" />
                    <!-- <span class="note average">{{ $t("Might Be Too Low") }}<s/span> -->
                </div>
            </div>
        </div>
        <AllArchiveAssessments :showAssessments="showAssessments" :archivesAssessmentsData="archivesAssessmentsData" :toggleAllAssessmentsArchive="toggleAllAssessmentsArchive" />

        <div class="candidatesWrapper" v-if="this.display === 'candidates'">
            <!-- <CandidateArchiveCard v-for="i in 9" :key="i" :passed="true" /> -->
            <CandidateCard2 v-for="cand in Store.selectedCandidates" :key="cand._id" :candidateInfo="cand" :candidate="cand" :passed="true" :backgroundHeader="[1]" />
        </div>
        <div v-else>
            <ArchivedAssessments :archivesAssessmentsData="archivesAssessmentsData" :toggleAllAssessmentsArchive="toggleAllAssessmentsArchive" />
        </div>
        <div class="candidatesChartWrapper">
            <div v-if="this.Store.selectedCandidates[0]?.results?.length > 2">
                <SpiderwebChart :data="getSpiderWebChartData()" :labels="chartLabels" />
            </div>
            <div v-else>
                <EmptySpyderWebChart />
            </div>
            <!-- <candidatesChart :histogramData="this.histogramData" /> -->
            <BarCompare :data="Store.getBarData" />
        </div>
    </div>
</template>

<script>
import { useStore } from "@/store";
import CandidateCard2 from "@/components/CandidateCard2.vue";
// import CandidateArchiveCard from "@/components/CandidateArchiveCard.vue";
import ArchivedAssessments from "@/components/ArchivedAsessments.vue";
import AllArchiveAssessments from "@/components/unsued/AllArchiveAssessments.vue";
// import candidatesChart from "@/components/candidatesChart.vue";
import SpiderwebChart from "@/charts/SpiderwebChart.vue";
import BarCompare from "@/charts/barCampare.vue";
import EmptySpyderWebChart from "@/charts/EmptySpyderWebChart.vue";

export default {
    name: "ComparaisonView",
    components: {
        CandidateCard2,
        // CandidateArchiveCard,
        ArchivedAssessments,
        AllArchiveAssessments,
        // candidatesChart,
        SpiderwebChart,
        BarCompare,
        EmptySpyderWebChart,
    },
    props: {},
    beforeUnmount() {
        this.Store.resetSelectedCandidates();
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    data() {
        return {
            bardata: [
                { group: "Abderraouf", bar1: 90, bar2: 65, bar3: 77 },
                { group: "Abdelghani", bar1: 83, bar2: 70, bar3: 94 },
                { group: "Sami", bar1: 68, bar2: 94, bar3: 86 },
            ],
            display: "candidates",
            showAssessments: false,
            archivesAssessmentsData: [
                {
                    title: "graphic designers",
                    score: 90,
                    time: "1h:20min:15s",
                    passed: true,
                    candidates: "12",
                },
                {
                    title: "designers",
                    score: 90,
                    time: "1h:20min:15s",
                    passed: false,
                    candidates: "12",
                },
                {
                    title: "designers",
                    score: 40,
                    time: "1h:20min:15s",
                    passed: false,
                    candidates: "12",
                },
                {
                    title: "graphic designers",
                    score: 50,
                    time: "1h:20min:15s",
                    passed: true,
                    candidates: "12",
                },
                {
                    title: "graphic designers",
                    score: 10,
                    time: "1h:20min:15s",
                    passed: true,
                    candidates: "12",
                },
                {
                    title: "graphic designers",
                    score: 90,
                    time: "1h:20min:15s",
                    passed: true,
                    candidates: "12",
                },
                {
                    title: "graphic designers",
                    score: 90,
                    time: "1h:20min:15s",
                    passed: false,
                    candidates: "12",
                },
                {
                    title: "designers",
                    score: 40,
                    time: "1h:20min:15s",
                    passed: false,
                    candidates: "12",
                },
                {
                    title: "graphic designers",
                    score: 90,
                    time: "1h:20min:15s",
                    passed: true,
                    candidates: "12",
                },
                {
                    title: "graphic designers",
                    score: 90,
                    time: "1h:20min:15s",
                    passed: false,
                    candidates: "12",
                },
                {
                    title: "designers",
                    score: 40,
                    time: "1h:20min:15s",
                    passed: false,
                    candidates: "12",
                },
                {
                    title: "graphic designers",
                    score: 90,
                    time: "1h:20min:15s",
                    passed: true,
                    candidates: "12",
                },
                {
                    title: "graphic designers",
                    score: 90,
                    time: "1h:20min:15s",
                    passed: false,
                    candidates: "12",
                },
                {
                    title: "designers",
                    score: 40,
                    time: "1h:20min:15s",
                    passed: false,
                    candidates: "12",
                },
                {
                    title: "graphic designers",
                    score: 90,
                    time: "1h:20min:15s",
                    passed: true,
                    candidates: "12",
                },
                {
                    title: "graphic designers",
                    score: 90,
                    time: "1h:20min:15s",
                    passed: false,
                    candidates: "12",
                },
                {
                    title: "designers",
                    score: 40,
                    time: "1h:20min:15s",
                    passed: false,
                    candidates: "12",
                },
            ],
            chartData: [50, 80, 60, 90, 70], // Example data values
            chartLabels: ["Label 1", "Label 2", "Label 3", "Label 4", "Label 5"], //
            histogramData: [
                { label: "january", value: 400 },
                { label: "February", value: 350 },
                { label: "March", value: 53 },
                { label: "April", value: 200 },
                { label: "July", value: 45 },
                { label: "Aout", value: 310 },
                { label: "September", value: 500 },
                { label: "October", value: 200 },
                { label: "November", value: 35 },
                { label: "December", value: 42 },
            ],
            projectId: "",
            filteredCandidates: [],
            minScore: null,
            maxScore: null,
        };
    },
    methods: {
        getSpiderWebChartData() {
            let data = [];
            this.Store.selectedCandidates?.map((candidate) => {
                let candidateData = {
                    name: candidate.First_name,
                    results: this.getCandidateResults(candidate.results),
                };
                data.push(candidateData);
            });
            return data;
        },
        getCandidateResults(results) {
            let resultsCalc = results?.map((result) => {
                if (result.rangesPoint) {
                    result.totalPoints = result.rangesPoint;
                } else if (result.totalPoints === undefined || result.totalPoints === null) {
                    result.totalPoints = 0;
                }
                return result;
            });
            console.log(resultsCalc);
            return resultsCalc;
        },
        projectData() {
            if (this.projectId) {
                // const project = this.Store.projects.find((project) => project._id === this.projectId);
                let projectResults = [];
                let maxScore = 0;
                this.filteredCandidates.map((candidate) => {
                    projectResults.push(this.calculateScore(candidate));
                });
                let minScore = projectResults[0];
                projectResults.map((result) => {
                    if (result > maxScore) {
                        maxScore = result;
                    }
                    if (result < minScore) {
                        minScore = result;
                    }
                });
                this.maxScore = maxScore;
                this.minScore = minScore;
            }
        },
        toggleAssessmentsArchive() {
            this.display = "assessment";
        },
        toggleCandidatesArchive() {
            this.display = "candidates";
        },
        toggleAllAssessmentsArchive() {
            this.showAssessments = !this.showAssessments;
        },
        calculateAverageTime() {
            let averageTime = 0;
            this.filteredCandidates.map((candidate) => {
                averageTime += candidate.candidateTime;
            });
            averageTime = averageTime / this.filteredCandidates?.length;
            return this.calculateTime(averageTime);
        },
        calculateTime(time) {
            let minutes = Math.floor(time / (60 * 1000));
            if (minutes > 59) minutes = 59;

            let seconds = time % (60 * 1000);
            if (seconds > 59) seconds = 59;

            minutes = minutes.toString().padStart(2, "0");

            if (seconds < 10) {
                seconds = "0" + seconds.toString();
            } else {
                seconds = seconds.toString();
            }

            return `${minutes} min ${seconds} sec`;
        },
        getSuccessRate() {
            const project = this.Store.projects.find((project) => project._id === this.projectId);
            if (this.filteredCandidates?.length > 0) {
                const candidatesAbove80Percent = this.filteredCandidates.filter((candidate) => {
                    let score = 0;
                    candidate.results?.forEach((element) => {
                        score += (element.totalPoints * 100) / element.quesionsNbr;
                    });
                    const averageScore = score / candidate.results?.length;
                    return averageScore > project?.min_score; // Filter candidates with scores over 80%
                });

                const numberOfCandidatesAbove80Percent = candidatesAbove80Percent?.length;
                const totalCandidates = this.filteredCandidates?.length;

                const percentageAbove80Percent = (numberOfCandidatesAbove80Percent / totalCandidates) * 100;
                return percentageAbove80Percent.toFixed(2);
            } else return 0;
        },
        calculateScore(candidate) {
            let score = 0;
            candidate.results?.forEach((element) => {
                score += element.rangesPoint ? (element.rangesPoint * 100) / (element.quesionsNbr * 5) : (element.totalPoints * 100) / element.quesionsNbr;
            });
            const averageScore = score / candidate.results?.length;
            const roundedScore = averageScore.toFixed(2);
            return parseFloat(roundedScore);
        },
    },
    mounted() {
        if (this.Store.selectedCandidates.length === 0) {
            this.$router.go(-1);
        }
        this.projectId = this.$route?.query?.projectId;
        this.filteredCandidates = this.Store.selectedCandidates;
        this.projectData();
    },
};
</script>

<style scoped lang="scss">
.archiveWrapper {
    display: flex;
    flex-direction: column;
    padding: 0% 1%;
    margin-bottom: 5%;
    .btns_wrapper {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;

        .btns {
            width: 25%;
            display: flex;
            justify-content: space-between;
            background-color: white;
            border-radius: 20px;

            button {
                width: 50%;
                border: none;
                cursor: pointer;
                font-weight: 500;
                font-size: 16px;
                transition: all 0.2s ease-in-out;
                &:hover {
                    font-size: 14px;
                }
            }
            .active {
                color: white;
                font-weight: 700;
                background-color: #3361ff;
                border-radius: 20px;
            }
            .notActive {
                color: #82888d;
                background-color: white;
                border-radius: 20px;
            }
        }
    }
    .candidatesWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
    }
}

.grid-row-widgets {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 16px;
    width: 100%;
    margin: 1% 0;
}

.widget,
.widget-2 {
    background: #fff;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
}

.background-1 {
    background: linear-gradient(135deg, #868cff 0%, #2196f3 100%);
}

.background-1 > div > .widget-info {
    color: #fff;
}

.background-1 > div > .widget-title {
    color: #e9edf7;
}

.background-2 {
    background: linear-gradient(135deg, #fab2ff 0%, #2196f3 100%);
}

.background-2 > div > .widget-info {
    color: #fff;
}

.background-2 > div > .widget-title {
    color: #e9edf7;
}
.widget-title {
    display: block;
    font-weight: 500;
    font-size: 16px;
    font-weight: 700;
    color: #7d8fb3;
    margin-bottom: 8px;
    text-align: left;
}

.widget-info {
    display: block;
    font-weight: 700;
    font-size: 28px;
    color: #1b2559;
    text-align: left;
}

.candidatesChartWrapper {
    width: 100%;
    border-radius: 20px;
    // padding: 16px;
    display: flow-root;
    text-align: left;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 38% 60%;
    gap: 2%;
    > :nth-child(1) {
        background-color: #fff;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.b-btn {
    padding: 3px 14px;
    background-color: #05cd99;
    position: absolute;
    bottom: 10px;
    right: 5px;
    color: white;
    border-radius: 8px;
}
.b-btn2 {
    padding: 3px 14px;
    background-color: #f3c84a;
    position: absolute;
    bottom: 10px;
    right: 5px;
    color: white;
    border-radius: 8px;
}
</style>
