<template>
    <!---->
    <div class="calendar-parent">
        <div class="soon">{{ $t("Coming Soon") }}.</div>
        <calendar-view
            :show-date="showDate"
            :items="items"
            :enableDragDrop="true"
            :enable-date-selection="true"
            @date-selection-start="setSelection"
            @date-selection="setSelection"
            @date-selection-finish="finishSelection"
            @drop-on-date="onDrop"
            @click-date="onClickDay"
            @click-item="onClickItem"
            class="theme-default holiday-us-traditional holiday-us-official"
        >
            <template #header="{ headerProps }">
                <calendar-view-header :header-props="headerProps" @input="setShowDate" />
            </template>
        </calendar-view>
    </div>
</template>

<script>
import { CalendarView, CalendarViewHeader, CalendarMath } from "vue-simple-calendar";
import "../../../node_modules/vue-simple-calendar/dist/css/default.css";
import "../../../node_modules/vue-simple-calendar/dist/css/holidays-us.css";
import "../../../node_modules/vue-simple-calendar/dist/style.css";
export default {
    name: "TeamCalendar",
    data: function () {
        return {
            showDate: this.thisMonth(1),
            message: "",
            startingDayOfWeek: 0,
            disablePast: false,
            disableFuture: false,
            displayPeriodUom: "month",
            displayPeriodCount: 1,
            displayWeekNumbers: false,
            showTimes: true,
            selectionStart: null,
            selectionEnd: null,
            newItemTitle: "",
            newItemStartDate: "",
            newItemEndDate: "",
            useDefaultTheme: true,
            useHolidayTheme: true,
            useTodayIcons: false,
            draggedItem: null,
            items: [
                /*{
                    id: "e0",
                    startDate: "2018-01-05",
                },*/
                {
                    id: "e1",
                    startDate: this.thisMonth(15, 18, 30),
                    style: "background: rgba(254, 127, 46, 0.2);",
                },
                {
                    id: "e2",
                    startDate: this.thisMonth(15),
                    title: "Single-day item with a long title",
                    style: "background: rgba(226, 137, 242, 0.2);",
                },
                {
                    id: "e3",
                    startDate: this.thisMonth(7, 9, 25),
                    endDate: this.thisMonth(10, 16, 30),
                    title: "Multi-day item with a long title and times",
                },
                {
                    id: "e4",
                    startDate: this.thisMonth(23),
                    title: "My Birthday!",
                    classes: "birthday",
                    url: "https://en.wikipedia.org/wiki/Birthday",
                },
                {
                    id: "e5",
                    startDate: this.thisMonth(5),
                    endDate: this.thisMonth(12),
                    title: "Multi-day item",
                    classes: "purple",
                    tooltip: "This spans multiple days",
                },
                {
                    id: "foo",
                    startDate: this.thisMonth(29),
                    title: "Same day 1",
                },
                {
                    id: "e6",
                    startDate: this.thisMonth(29),
                    title: "Same day 2",
                    classes: "orange",
                },
                {
                    id: "e7",
                    startDate: this.thisMonth(29),
                    title: "Same day 3",
                },
                {
                    id: "e8",
                    startDate: this.thisMonth(29),
                    title: "Same day 4",
                    classes: "orange",
                },
                {
                    id: "e9",
                    startDate: this.thisMonth(29),
                    title: "Same day 5",
                },
                {
                    id: "e10",
                    startDate: this.thisMonth(29),
                    title: "Same day 6",
                    classes: "orange",
                },
                {
                    id: "e11",
                    startDate: this.thisMonth(29),
                    title: "Same day 7",
                },
            ],
        };
    },
    components: {
        CalendarView,
        CalendarViewHeader,
    },
    computed: {
        userLocale() {
            return CalendarMath.getDefaultBrowserLocale;
        },
        dayNames() {
            return CalendarMath.getFormattedWeekdayNames(this.userLocale, "long", 0);
        },
        themeClasses() {
            return {
                "theme-default": this.useDefaultTheme,
                "holiday-us-traditional": this.useHolidayTheme,
                "holiday-us-official": this.useHolidayTheme,
            };
        },
        myDateClasses() {
            // This was added to demonstrate the dateClasses prop. Note in particular that the
            // keys of the object are `yyyy-mm-dd` ISO date strings (not dates), and the values
            // for those keys are strings or string arrays. Keep in mind that your CSS to style these
            // may need to be fairly specific to make it override your theme's styles. See the
            // CSS at the bottom of this component to see how these are styled.
            const o = {};
            const theFirst = this.thisMonth(1);
            const ides = [2, 4, 6, 9].includes(theFirst.getMonth()) ? 15 : 13;
            const idesDate = this.thisMonth(ides);
            o[CalendarMath.isoYearMonthDay(idesDate)] = "ides";
            o[CalendarMath.isoYearMonthDay(this.thisMonth(21))] = ["do-you-remember", "the-21st"];
            return o;
        },
    },
    mounted() {
        this.newItemStartDate = CalendarMath.isoYearMonthDay(CalendarMath.today());
        this.newItemEndDate = CalendarMath.isoYearMonthDay(CalendarMath.today());
    },
    methods: {
        periodChanged() {
            // range, eventSource) {
            // Demo does nothing with this information, just including the method to demonstrate how
            // you can listen for changes to the displayed range and react to them (by loading items, etc.)
            //console.log(eventSource)
            //console.log(range)
        },
        thisMonth(d, h, m) {
            const t = new Date();
            return new Date(t.getFullYear(), t.getMonth(), d, h || 0, m || 0);
        },
        onClickDay(d) {
            this.selectionStart = null;
            this.selectionEnd = null;
            this.message = `You clicked: ${d.toLocaleDateString()}`;
        },
        onClickItem(e) {
            this.message = `You clicked: ${e.title}`;
        },
        setShowDate(d) {
            this.message = `Changing calendar view to ${d.toLocaleDateString()}`;
            this.showDate = d;
        },
        setSelection(dateRange) {
            this.selectionEnd = dateRange[1];
            this.selectionStart = dateRange[0];
        },
        finishSelection(dateRange) {
            this.setSelection(dateRange);
            this.message = `You selected: ${this.selectionStart.toLocaleDateString()} -${this.selectionEnd.toLocaleDateString()}`;
        },
        onDrop(item, date) {
            this.message = `You dropped ${item.id} on ${date.toLocaleDateString()}`;
            // Determine the delta between the old start date and the date chosen,
            // and apply that delta to both the start and end date to move the item.
            const eLength = CalendarMath.dayDiff(item.startDate, date);
            item.originalItem.startDate = CalendarMath.addDays(item.startDate, eLength);
            item.originalItem.endDate = CalendarMath.addDays(item.endDate, eLength);
        },
        clickTestAddItem() {
            this.items.push({
                startDate: this.newItemStartDate,
                endDate: this.newItemEndDate,
                title: this.newItemTitle,
                id: "e" + Math.random().toString(36).substr(2, 10),
            });
            this.message = "You added a calendar item!";
        },
        onDragStart(item) {
            this.draggedItem = item;
        },
        onDragEnter(event) {
            event.preventDefault();
        },
        onDragOver(event) {
            event.preventDefault();
        },
        onDragLeave(event) {
            console.log(event);
        },
        /*onDrop(event, date) {console.log(event);
      const index = this.draggedItem.type === 'event'
        ? this.events.findIndex((event) => event.id === this.draggedItem.id)
        : this.tasks.findIndex((task) => task.id === this.draggedItem.id);
      
      if (index !== -1) {
        const item = this.draggedItem.type === '<   event' ? this.events[index] : this.tasks[index];
        item.date = date;
        this.draggedItem = null;
      }
    },*/
    },
};
</script>

<style lang="scss" scoped>
.calendar-parent {
    display: flex;
    max-height: 80%;
    background-color: #fff;
    height: 80%;
    position: relative;
}

.soon {
    z-index: 20;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0000008c;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    color: #fff;
}
</style>
