<template>
    <div class="container">
        <div class="grid-span-col-3">
            <h2>{{ Role }}</h2>
        </div>

        <div class="grid-span-row-3" style="width: 100%; position: relative; padding-left: 5%">
            <img loading="lazy" decoding="async" src="@/assets/Images/icons/profilepic.png" alt="" width="250" height="450" style="width: 100%" />
            <span class="hello"> "Hello! How Can I Help You?"</span>
            <img loading="lazy" decoding="async" src="@/assets/Images/icons/imagedecor.svg" alt="" width="245" height="445" class="decore" />
        </div>

        <div class="candidate-info grid-span-row-3">
            <div class="grid-span-col-3">
                <h1>{{ FulleName }}</h1>
            </div>

            <div class="motivation">
                <span> Motivated By</span>
                <div>
                    <span v-for="motivation in Motivations" :key="motivation">{{ motivation }}</span>
                </div>
            </div>
            <div v-for="skill in Skills" :key="skill.label" class="skill-box">
                <!--<hr class="border" :style="{'background-color': skill.color}">-->
                <span>{{ skill.label }}</span>
                <span> {{ skill.description }}</span>
            </div>

            <TabsWrapper>
                <TabVue title="assets/Images/icons/lion.svg">
                    <p>
                        Lions are leaders. They are usually the bosses at work…or at least they think they are! They are decisive, bottom line folks who are observers, not watchers or listeners. They
                        love to solve problems. They are usually individualists who love to seek new adventures and opportunities. Lions are very confident and self-reliant. In a group setting, if no
                        one else instantly takes charge, the Lion will. Unfortunately, if they don’t learn how to tone down their aggressiveness, their natural dominating traits can cause problems
                        with others. Most entrepreneurs are strong lions, or at least have a lot of lion in them.
                    </p></TabVue
                >
                <TabVue title="assets/Images/icons/plus.svg">
                    <ul>
                        <li v-for="strength in Strengths" :key="strength">
                            {{ strength }}
                        </li>
                    </ul></TabVue
                >
                <TabVue title="assets/Images/icons/minus.svg"
                    ><ul>
                        <li v-for="weakness in Weaknesses" :key="weakness">
                            {{ weakness }}
                        </li>
                    </ul></TabVue
                >
            </TabsWrapper>
        </div>

        <button class="viewbtn">Upgrade to PRO to get access to all features!</button>
    </div>
</template>

<script>
import TabsWrapper from "@/components/Tabs.vue";
import TabVue from "@/components/Tab.vue";
export default {
    name: "PersonalityTest2",
    components: {
        TabsWrapper,
        TabVue,
    },

    data() {
        return {
            Role: "Administrative Assistant",
            FulleName: "Anny Winslet",
            Skills: [
                {
                    label: "Basic Disposition",
                    description: "Fast Paced, Task Oriented",
                    color: "#8833FF",
                },
                {
                    label: "Communication Style",
                    description: "Great at initiating communication; not good at listening (one way communicator)",
                    color: "#33BFFF",
                },
                {
                    label: "Time Management",
                    description: "Lions focus on NOW instead of distant future. They get a lot more done in a lot less time than their peers. Hate wasting time; and like to get right to the point.",
                    color: "#FFCB33",
                },
                {
                    label: "Decision Making",
                    description: "Impulsive; makes quick decisions with goal or end result in mind. Results-focused. Needs very few facts to make a decision.",
                    color: "#29CC39",
                },
                {
                    label: "In Pressure or Tense Situations",
                    description: "The lion takes command and becomes autocratic.",
                    color: "#EB5757",
                },
                {
                    label: "Greatest Needs",
                    description: "The lion needs to see results, experience variety, and face new challenges. He needs to solve problems and wants direct answers.",
                    color: "#2F80ED",
                },
            ],
            Motivations: ["Challenge", "Action", "Power", "Credit for achievement"],
            PersonalityType: "Lion",
            PersonalityDescription:
                "Lions are leaders. They are usually the bosses at work…or at least they think they are! \
                                            They are decisive, bottom line folks who are observers, not watchers or listeners. \
                                            They love to solve problems. They are usually individualists who love to seek new adventures \
                                            and opportunities. Lions are very confident and self-reliant. In a group setting, if no one \
                                            else instantly takes charge, the Lion will. Unfortunately, if they don’t learn how to tone down \
                                            their aggressiveness, their natural dominating traits can cause problems with others. Most \
                                            entrepreneurs are strong lions, or at least have a lot of lion in them.",
            Strengths: [
                "Decisive",
                "Goal-oriented",
                "Achievement driven",
                "Gets results",
                "Independent",
                "Risk-taker",
                "Takes charge",
                "Takes initiative",
                "Self-starter",
                "Persistent",
                "Efficient",
                "Competitive",
                "Enjoys challenges, variety and change",
                "Driven to complete projects quickly and effectively.",
            ],
            Weaknesses: [
                "Impatient",
                "Blunt",
                "Poor listener",
                "Impulsive",
                "Demanding",
                "May view projects more important than people",
                "Can be insensitive to the feelings of others",
                "May “run over” others who are slower to act or speak",
                "Self-starter • Fears inactivity, relaxation",
                "Quickly bored by routine or mechanics",
            ],
        };
    },
};
</script>

<style scoped>
.container {
    width: fit-content;
    display: grid;
    grid-template-columns: 25% 75%;
    gap: 30px;
    padding: 24px 42px 24px 8px;
    background-image: url("@/assets/Images/icons/red-half-circle.svg"), url("@/assets/Images/icons/green-circle.svg");
    background-position:
        top 11% left,
        bottom 33% right;
    background-repeat: no-repeat;
}
.grid-span-col-3 {
    grid-column: span 3;
}

.grid-span-row-3 {
    grid-row: span 3;
}
ul {
    padding: 0% 5%;
}
li {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 10.5315px;
    line-height: 16px;
    color: #4d5e80;
}
p {
    display: flex;
    flex-direction: column;
}
p > span {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 15.1654px;
    line-height: 21px;
    color: #4d5e80;
}
.candidate-info {
    display: grid;
    grid-template-columns: 30% 30% 40%;
    gap: 26px;
    margin: 0%;
    margin-right: 7%;
}

.skill-box {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.03);
    border-radius: 4.2126px;
    align-items: center;
    padding: 3% 12%;
    justify-content: center;
    text-align: left;
    gap: 8px;
    height: 100%;
}
/*.skill-box::before{
    content:'';
  width:3px;
  height:42px;
  background:#4829DE;
  position:relative;
  top: 27%;
  right: 61%;
 }*/
.skill-box > :nth-child(1) {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 14.7441px;
    line-height: 15px;
    color: #4d5e80;
    width: 100%;
}
.skill-box > :nth-child(2) {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 10.5315px;
    line-height: 13px;
    color: #4d5e80;
    width: 100%;
}

.motivation {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 15.1654px;
    line-height: 21px;
    color: #4d5e80;
    gap: 8px;
    display: flex;
    flex-direction: column;
    height: 80%;
    background: #ffffff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
    padding: 2% 0% 3% 4%;
    justify-content: center;
    grid-column: 1 / span 2;
    grid-row-start: 2;
}
.motivation > div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 10px;
}
.motivation > div > span {
    background: #2196f3;
    border-radius: 4.2126px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 13.4803px;
    line-height: 21px;
    color: #ffffff;
    padding: 1%;
}
.candidate-info > div > h1 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 54.7638px;
    line-height: 63px;
    color: #4d5e80;
    width: 65%;
    margin-top: 0px;
    margin-bottom: 0px;
}
h2 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 25.2756px;
    line-height: 34px;
    color: #4d5e80;
}
.hello {
    position: absolute;
    bottom: 25%;
    left: 7%;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 21.063px;
    line-height: 25px;
    color: #ffffff;
    padding: 0% 5%;
}
.decore {
    position: absolute;
    bottom: 19%;
    left: 4%;
}
.viewbtn {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    border: none;
    letter-spacing: -0.02em;
    border-radius: 24px;
    color: #fff;
    background: linear-gradient(135deg, #868cff 0%, #2196f3 100%);
    width: 37%;
    padding: 7% 7%;
    position: relative;
    margin-top: 13%;
    margin-bottom: 10%;
    grid-column-end: 3;
    display: flex;
    justify-self: end;
}
</style>
