<template>
    <div>
        <h1>Access & Security</h1>
        <hr />
        <div class="button-group">
            <button :class="{ inactive: !securityTab }" @click="swithcTab">Security</button>
            <button :class="{ inactive: securityTab }" @click="swithcTab">Deligated Access</button>
        </div>
        <div v-if="securityTab" id="security">
            <div class="form-control">
                <label for="emsail">Sign-in Email</label>
                <input type="email" name="email" value="johnsmith@gmail.com" />
            </div>
            <div class="form-control">
                <label for="password">Password</label>
                <span class="hidden-password" v-if="!showPassword" @click="showPasswordField">Change Password</span>
                <input v-if="showPassword" type="text" name="password" id="password" value="password" />
            </div>
            <hr />
            <div class="form-control">
                <label for="fa-auth">2-FA Authentification</label>
                <input type="checkbox" name="fa-auth" />
            </div>
            <div class="form-control">
                <label for="phone">Phone Number</label>
                <input type="tel" name="phone" value="+380 93 123 45 67" />
            </div>
            <div class="form-control">
                <label for="fa-auth">Reserve Codes</label>
                <span>9 out of 10 left</span>
            </div>
            <button class="btn">+ Generate new codes</button>
            <hr />
            <span class="text-span">Last Sign-in</span>
            <span class="text-span">today at 18:34</span>
            <div class="active-sessions">
                <span>Total Active Session (5)</span>
                <button class="btn outline-btn">All</button>
            </div>
            <div class="session">
                <span>DESKTOP-6TIG6EC • Kyiv, Ukraine</span>
                <span class="secondary-text">Chrome • Used right now</span>
            </div>
            <div class="session">
                <span>Iphone 11 • Kyiv, Ukraine</span>
                <span class="secondary-text">Chrome • 04/19/2022</span>
            </div>
            <button class="btn">+ Reset all active sessions</button>
        </div>
        <div v-if="!securityTab" id="access">
            <span class="title-span">You’ve been granted access to the following cabinets:</span>
            <div>
                <div class="profile">
                    <div class="profile-name">
                        <img loading="lazy" decoding="async" src="@/assets/Images/profile.png" alt="profile" />
                        <span>Profile Name</span>
                    </div>
                    <button class="btn outline-btn">Sign in</button>
                </div>
                <div class="profile">
                    <div class="profile-name">
                        <img loading="lazy" decoding="async" src="@/assets/Images/profile.png" alt="profile" />
                        <span>Profile Name</span>
                    </div>
                    <button class="btn outline-btn">Sign in</button>
                </div>
            </div>
            <hr />
            <span class="title-span">You gave access to the following cabinets:</span>
            <div>
                <div class="grid-row table-header">
                    <div>Name</div>
                    <div>Access</div>
                    <div>Actions</div>
                </div>
                <div class="grid-row table-data">
                    <div class="name">Jake Gilehaal</div>
                    <div class="access">View Channels & Profiles, View Finances Accure Money</div>
                    <div class="actions">
                        <button>
                            <img loading="lazy" decoding="async" src="@/assets/Images/icons/edit.svg" alt="edit" />
                        </button>
                        <button>
                            <img loading="lazy" decoding="async" src="@/assets/Images/icons/delete.svg" alt="delete" />
                        </button>
                    </div>
                </div>
                <div class="grid-row table-data">
                    <div class="name">Jake Gilehaal</div>
                    <div class="access">View Channels & Profiles, View Finances Accure Money</div>
                    <div class="actions">
                        <button>
                            <img loading="lazy" decoding="async" src="@/assets/Images/icons/edit.svg" alt="edit" />
                        </button>
                        <button>
                            <img loading="lazy" decoding="async" src="@/assets/Images/icons/delete.svg" alt="delete" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SecuritySettingsView",
    data: function () {
        return {
            securityTab: true,
            showPassword: false,
        };
    },
    methods: {
        swithcTab: function (e) {
            if (e["target"]["className"] === "inactive") this.securityTab = !this.securityTab;
        },
        showPasswordField: function () {
            this.showPassword = !this.showPassword;
        },
    },
};
</script>

<style scoped>
h1 {
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
}

hr {
    all: unset;
    display: block;
    margin: 16px 24px;
    background: #cfd8dc;
    height: 1px;
}

button {
    all: unset;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    padding: 4px 20px;
    background: #2196f3;
    box-shadow:
        0px 0px 2px rgba(0, 0, 0, 0.14),
        0px 2px 2px rgba(0, 0, 0, 0.12),
        0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 70px;
}

label {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.5px;
    color: #333333;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input {
    all: unset;
    margin: 0px;
    padding: 0px;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    text-align: end;
}

#access,
#security {
    padding: 24px;
}

.inactive {
    background: none;
    border: none;
    box-shadow: none;
    color: #82888d;
}

.button-group {
    display: flex;
    justify-content: center;
}

.btn {
    display: block;
    margin: 16px;
    margin-right: 0px;
    margin-left: auto;
}

.outline-btn {
    background: none;
    border: none;
    box-shadow: none;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.5px;
    color: #2196f3;
}
.form-control {
    display: flex;
    justify-content: space-between;
    margin: 24px 0px;
}

.hidden-password {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.5px;
    color: #2196f3;
}

.text-span {
    display: block;
    margin: 16px 0px;
}

.active-sessions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -8px;
}

.session {
    border-bottom: 1px solid #cfd8dc;
    padding-bottom: 16px;
}

.session > span {
    display: block;
    margin: 8px 0px;
}

.secondary-text {
    opacity: 0.8;
}

.title-span {
    display: block;
    margin: 24px 0px;
    font-weight: 500;
    font-size: 16px;
    color: #333333;
}

.profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-name {
    display: flex;
    align-items: center;
}

.profile-name > img {
    border-radius: 50%;
    margin-right: 16px;
}

.grid-row {
    display: grid;
    grid-template-columns: 3fr 5fr 2fr;
    gap: 24px;
    padding: 0px 16px;
}

.table-header {
    font-weight: 700;
    font-size: 16px;
    color: #333333;
    margin-bottom: 16px;
}

.table-data {
    align-items: center;
    border-top: 1px solid #cfd8dc;
    padding: 8px 0px;
}

.table-data > .name {
    font-weight: 400;
    font-size: 16px;
    color: #333333;
}

.table-data > .access {
    font-weight: 400;
    font-size: 13px;
    color: #333333;
}

.actions {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.actions > button {
    all: unset;
}
</style>
