<template>
    <congratsPopup :isVisible="showConfirm" :toggleConfirmEmail="toggleConfirmEmail" />

    <div class="pt-[20%] lg:pt-[10%] flex justify-center p-4 w-full h-fit bg-gray-100">
        <div class="w-full lg:w-2/3">
            <img src="@/assets/websummit/websummit.png" class="w-full h-fit rounded-md" alt="" />
            <div class="bg-white rounded-md text-left my-2 p-4">
                <h1 class="text-l l:text-xl lg:text-3xl font-semibold mb-4">Web Summit Future Partners</h1>
                <p class="text-md l:text-l my-2">
                    <span class="font-bold">Partner with GO PLATFORM at Web Summit Qatar! </span>
                    The future of hiring is skills-based, and GO PLATFORM is leading the charge with cutting-edge AI technology. We're heading to Doha (Feb 23-26, 2025) to connect with
                    forward-thinking organizations ready to embrace a smarter, more effective approach to talent acquisition. Why meet with us? Because GO PLATFORM:
                </p>
                <ul class="list-disc text-sm l:text-md mx-8 my-2">
                    <li>Offers a skills-first, not pedigree-first, solution</li>
                    <li>Leverages AI to identify the best-fit candidates, regardless of background</li>
                    <li>Provides unparalleled insights into your workforce's skills gaps</li>
                    <li>Keeps you ahead of the competition in the talent race.</li>
                </ul>
                <p class="text-sm l:text-md">Don't get left behind. Tell us who you'd like to meet at Web Summit by completing this form. Let's shape the future of work, together!</p>
            </div>

            <form @submit.prevent="submitForm" class="space-y-4 bg-white p-6 rounded-md shadow-md text-left">
                <!-- <FloatingLabel label="Enter answer" name="Answer" type="text" v-model="option.answer" :setInputs="setInputs" :answerIndx="index" :value="option.answer" /> -->
                <p class="font-bold text-md l:text-xl my-4">About you</p>
                <div class="grid l:grid-cols-2 gap-8 l:gap-6">
                    <FloatingLabel
                        label="Full Name"
                        name="FullName"
                        type="text"
                        v-model="form.fullName"
                        required
                        :setInputs="setInputs"
                        :errorMgs="errMsg.fullName"
                        :value="form.fullName"
                        answerIndx="fullName"
                    />

                    <FloatingLabel label="Email" :errorMgs="errMsg.email" name="Email" type="email" v-model="form.email" required :setInputs="setInputs" :value="form.email" answerIndx="email" />
                    <FloatingLabel label="Company" name="Company" :errorMgs="errMsg.company" type="text" v-model="form.company" :setInputs="setInputs" :value="form.company" answerIndx="company" />
                    <FloatingLabel
                        label="Other Information"
                        :errorMgs="errMsg.otherInfo"
                        name="OtherInfo"
                        type="text"
                        v-model="form.otherInfo"
                        :setInputs="setInputs"
                        :value="form.otherInfo"
                        answerIndx="otherInfo"
                    />
                </div>
                <div class="flex flex-col gap-2">
                    <div class="my-10 relative mb-10">
                        <p class="font-bold text-md l:text-xl my-4">What is the potential collaboration?</p>
                        <div v-for="(option, index) in collaborationOptions" :key="index" class="flex items-center gap-2 cursor-pointer w-fit" @click="toggleCheckbox('collaboration', option)">
                            <input type="checkbox" :checked="form.collaboration.includes(option)" />
                            <label class="mx-5">{{ option }}</label>
                        </div>
                        <p v-if="errMsg.collaboration" class="absolute top-[100%] text-red-500 text-xs mt-1">
                            {{ errMsg.collaboration }}
                        </p>
                    </div>

                    <div class="my-4 relative mb-8">
                        <p class="font-bold my-4 text-md l:text-xl">Which of the following best describes your organization?</p>
                        <div v-for="(option, index) in organizationTypes" :key="index" class="flex items-center gap-2 cursor-pointer w-fit" @click="toggleCheckbox('organization', option)">
                            <input type="checkbox" :checked="form.organization.includes(option)" />
                            <label class="mx-5">{{ option }}</label>
                        </div>
                        <p v-if="errMsg.organization" class="absolute top-[100%] text-red-500 text-xs mt-1">
                            {{ errMsg.organization }}
                        </p>
                    </div>
                </div>
                <div class="w-full flex justify-center">
                    <button
                        v-if="!loading"
                        type="submit"
                        class="hero-button w-1/4 my-6 px-4 h-12 bg-[#2196f3] shadow hover:opacity-[0.8] rounded text-base text-center flex justify-center whitespace-nowrap text-[18px] items-center text-white font-bold relative overflow-hidden"
                    >
                        Submit
                    </button>
                    <button
                        v-else
                        type="submit"
                        class="hero-button w-1/4 my-6 px-4 h-12 bg-[#2196f3] shadow hover:opacity-[0.8] rounded text-base text-center flex justify-center whitespace-nowrap text-[18px] items-center text-white font-bold relative overflow-hidden"
                    >
                        <svg class="animate-spin absolute h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import FloatingLabel from "@/components/ReusableComponents/FloatingLabel";
import congratsPopup from "./congratsPopup.vue";

import axios from "axios";

export default {
    name: "WebSummit",
    components: {
        FloatingLabel,
        congratsPopup,
    },
    data() {
        return {
            loading: false,
            showConfirm: false,
            form: {
                fullName: "",
                email: "",
                company: "",
                otherInfo: "",
                collaboration: [],
                organization: [],
            },
            collaborationOptions: [
                "Transition our company into skills-first organization",
                "Integrate White-Label",
                "Project-Based Collaboration",
                "Distribute your solution within my Network/Country",
                "Technological",
                "Others",
            ],
            organizationTypes: [
                "AI Company",
                "Technology Company",
                "Staffing Agency/Talent Acquisition",
                "Consultancy/Managed Service Provider",
                "Capital Allocator",
                "Multi-National Corporation",
                "Others",
            ],
            errMsg: {
                fullName: "",
                email: "",
                company: "",
                otherInfo: "",
                potential: "",
                type: "",
            },
        };
    },
    methods: {
        toggleConfirmEmail() {
            this.showConfirm = !this.showConfirm;
        },
        async submitForm() {
            let valid = this.verifyInputs();
            if (!valid) return;
            try {
                this.loading = true;
                const response = await axios.post("https://server.go-platform.com/websummit", this.form);
                console.log("Form Submitted Successfully:", response.data);
            } catch (error) {
                console.error("Error submitting form:", error);
            } finally {
                this.toggleConfirmEmail();
                this.form = {
                    fullName: "",
                    email: "",
                    company: "",
                    otherInfo: "",
                    collaboration: [],
                    organization: [],
                };
                this.loading = false;
            }
        },

        verifyInputs() {
            this.errMsg = { fullName: "", email: "", otherInfo: "", collaboration: "", organization: "" };
            let valid = true;

            if (!this.form.fullName.trim()) {
                this.errMsg.fullName = "Full Name is required.";
                valid = false;
            }
            if (!this.form.company?.trim()) {
                this.errMsg.company = "Company is required.";
                valid = false;
            }
            if (!this.form.email.trim() || !/.+@.+\..+/.test(this.form.email)) {
                this.errMsg.email = "Valid Email is required.";
                valid = false;
            }
            if (this.form.collaboration.length === 0) {
                this.errMsg.collaboration = "Please select at least one collaboration option.";
                valid = false;
            }
            if (this.form.organization.length === 0) {
                this.errMsg.organization = "Please select your organization type.";
                valid = false;
            }
            return valid;
        },

        toggleCheckbox(type, option) {
            if (this.form[type].includes(option)) {
                this.form[type] = this.form[type].filter((item) => item !== option);
            } else {
                this.form[type].push(option);
            }
        },
        setInputs(value, index) {
            this.form[index] = value;
        },
    },
};
</script>

<style scoped></style>
