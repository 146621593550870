<template>
    <div class="container">
        <div class="grid-span-col-3">
            <h2>{{ Role }}</h2>
        </div>

        <div class="grid-span-row-3" style="width: 100%; position: relative; padding-left: 5%">
            <img loading="lazy" decoding="async" src="@/assets/Images/icons/profilepic.png" alt="" width="250" height="450" style="width: 100%" />
            <span class="hello"> "Hello! How Can I Help You?"</span>
            <img loading="lazy" decoding="async" src="@/assets/Images/icons/imagedecor.svg" alt="" width="250" height="450" class="decore" />
        </div>

        <div class="candidate-info grid-span-row-3">
            <div class="grid-span-col-2">
                <h1>{{ FulleName }}</h1>
            </div>

            <div class="motivation grid-span-col-2">
                <span> Motivated By</span>
                <div>
                    <span v-for="motivation in Motivations" :key="motivation">{{ motivation }}</span>
                </div>
            </div>
            <div v-for="skill in Skills" :key="skill.label" class="skill-box">
                <!--<hr class="border" :style="{'background-color': skill.color}">-->
                <span>{{ skill.label }}</span>
                <span> {{ skill.description }}</span>
            </div>
        </div>

        <div class="grid-to-right box-background div-right">
            <p>
                <span> {{ PersonalityType }}</span>
                {{ PersonalityDescription }}
            </p>
        </div>

        <div class="grid-to-right box-background div-right">
            <span class="font-500-15-21">Natural Strengths:</span>
            <ul>
                <li v-for="strength in Strengths" :key="strength">
                    {{ strength }}
                </li>
            </ul>
        </div>

        <div class="grid-to-right box-background div-right">
            <span class="font-500-15-21"> Natural Weaknesses</span>
            <ul>
                <li v-for="weakness in Weaknesses" :key="weakness">
                    {{ weakness }}
                </li>
            </ul>
        </div>

        <button class="viewbtn">Upgrade to PRO to get access to all features!</button>
    </div>
</template>

<script>
export default {
    name: "PersonalityTest",
    data() {
        return {
            Role: "Administrative Assistant",
            FulleName: "Anny Winslet",
            Skills: [
                {
                    label: "Basic Disposition",
                    description: "Fast Paced, Task Oriented",
                    color: "#8833FF",
                },
                {
                    label: "Communication Style",
                    description: "Great at initiating communication; not good at listening (one way communicator)",
                    color: "#33BFFF",
                },
                {
                    label: "Time Management",
                    description: "Lions focus on NOW instead of distant future. They get a lot more done in a lot less time than their peers. Hate wasting time; and like to get right to the point.",
                    color: "#FFCB33",
                },
                {
                    label: "Decision Making",
                    description: "Impulsive; makes quick decisions with goal or end result in mind. Results-focused. Needs very few facts to make a decision.",
                    color: "#29CC39",
                },
                {
                    label: "In Pressure or Tense Situations",
                    description: "The lion takes command and becomes autocratic.",
                    color: "#EB5757",
                },
                {
                    label: "Greatest Needs",
                    description: "The lion needs to see results, experience variety, and face new challenges. He needs to solve problems and wants direct answers.",
                    color: "#2F80ED",
                },
            ],
            Motivations: ["Challenge", "Action", "Power", "Credit for achievement"],
            PersonalityType: "Lion",
            PersonalityDescription:
                "Lions are leaders. They are usually the bosses at work…or at least they think they are! \
                                            They are decisive, bottom line folks who are observers, not watchers or listeners. \
                                            They love to solve problems. They are usually individualists who love to seek new adventures \
                                            and opportunities. Lions are very confident and self-reliant. In a group setting, if no one \
                                            else instantly takes charge, the Lion will. Unfortunately, if they don’t learn how to tone down \
                                            their aggressiveness, their natural dominating traits can cause problems with others. Most \
                                            entrepreneurs are strong lions, or at least have a lot of lion in them.",
            Strengths: [
                "Decisive",
                "Goal-oriented",
                "Achievement driven",
                "Gets results",
                "Independent",
                "Risk-taker",
                "Takes charge",
                "Takes initiative",
                "Self-starter",
                "Persistent",
                "Efficient",
                "Competitive",
                "Enjoys challenges, variety and change",
                "Driven to complete projects quickly and effectively.",
            ],
            Weaknesses: [
                "Impatient",
                "Blunt",
                "Poor listener",
                "Impulsive",
                "Demanding",
                "May view projects more important than people",
                "Can be insensitive to the feelings of others",
                "May “run over” others who are slower to act or speak",
                "Self-starter • Fears inactivity, relaxation",
                "Quickly bored by routine or mechanics",
            ],
        };
    },
};
</script>

<style scoped>
.container {
    width: fit-content;
    display: grid;
    grid-template-columns: 25% 45% 30%;
    gap: 30px;
    padding: 24px 42px 24px 8px;
    background-image: url("@/assets/Images/icons/red-half-circle.svg"), url("@/assets/Images/icons/green-circle.svg");
    background-position:
        top 10% left,
        bottom 27% right;
    background-repeat: no-repeat;
}
.grid-span-col-3 {
    grid-column: span 3;
}
.grid-to-right {
    grid-column: 3 / 4;
    height: fit-content;
    width: 90%;
}
.grid-span-row-3 {
    grid-row: span 3;
}
.box-background {
    background-color: #fff;
    border-radius: 4.2126px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10.1102px;
    gap: 6.74px;
}
.grid-to-right > p {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 7.58268px;
    line-height: 12px;
}
li {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 7.58268px;
    line-height: 12px;
    color: #4d5e80;
}
p {
    display: flex;
    flex-direction: column;
}
p > span {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 15.1654px;
    line-height: 21px;
    color: #4d5e80;
}
.candidate-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin: 0%;
    margin-right: 7%;
}
.grid-span-col-2 {
    grid-column: span 2;
}
.skill-box {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.03);
    border-radius: 4.2126px;
    align-items: center;
    padding: 2% 10%;
    justify-content: center;
    text-align: left;
    gap: 8px;
    height: 100%;
}
/*.skill-box::before{
    content:'';
  width:3px;
  height:42px;
  background:#4829DE;
  position:relative;
  top: 27%;
  right: 61%;
 }*/
.skill-box > :nth-child(1) {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 14.7441px;
    line-height: 15px;
    color: #4d5e80;
    width: 100%;
}
.skill-box > :nth-child(2) {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 10.5315px;
    line-height: 13px;
    color: #4d5e80;
    width: 100%;
}
.border {
    transform: rotate(90deg);
    width: 23%;
    margin: 0%;
    border: 20px;
    height: 2px;
}
.motivation {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 15.1654px;
    line-height: 21px;
    color: #4d5e80;
    gap: 8px;
    display: flex;
    flex-direction: column;
    height: 90%;
    background: #ffffff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.03);
    border-radius: 4.2126px;
    padding: 0% 0% 2% 4%;
    justify-content: center;
}
.motivation > div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 10px;
}
.motivation > div > span {
    background: #2196f3;
    border-radius: 4.2126px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 13.4803px;
    line-height: 21px;
    color: #ffffff;
    padding: 1%;
}
.font-500-15-21 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 15.1654px;
    line-height: 21px;
    color: #4d5e80;
}
.candidate-info > div > h1 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 54.7638px;
    line-height: 63px;
    color: #4d5e80;
    width: 65%;
    margin-top: 0px;
    margin-bottom: 0px;
}
h2 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 25.2756px;
    line-height: 34px;
    color: #4d5e80;
}
.hello {
    position: absolute;
    bottom: 42%;
    left: 7%;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 21.063px;
    line-height: 25px;
    color: #ffffff;
    padding: 0% 5%;
}
.decore {
    position: absolute;
    bottom: 36%;
    left: 6%;
    height: 62%;
    width: 93%;
}
.div-right {
    width: 75%;
}
.viewbtn {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    border: none;
    letter-spacing: -0.02em;
    background: none;
    color: #2196f3;
    border-radius: 24px;
    color: #fff;
    background: linear-gradient(135deg, #868cff 0%, #2196f3 100%);
    left: 307%;
    width: 96%;
    padding: 17% 3%;
    position: relative;
    margin-top: 6%;
    margin-bottom: 40%;
    top: 16%;
}
</style>
