<template>
    <div class="library-container">
        <div class="flex flex-wrap gap-4" style="width: 100%; margin-top: 116px">
            <!-- <h2 class="font-semibold text-2" style="color: #0c58b6">All</h2> -->
            <h2 v-for="(category, index) in categories" :key="index" :style="{ color: category == this.category ? '#0c58b6' : '' }" class="font-semibold text-3">
                {{ category }}
            </h2>
            <!-- <h2 class="font-semibold text-2">Personality and Culture</h2>
    <div class="library-container">
        <div class="flex flex-wrap gap-4" style="width: 100%; margin-top: 116px">
             <h2 class="font-semibold text-2" style="color: #0c58b6">All</h2> -->
            <h2 v-for="(category, index) in categories" :key="index" :style="{ color: category == this.category ? '#0c58b6' : '' }" class="font-semibold text-3">
                {{ category }}
            </h2>
            <!-- <h2 class="font-semibold text-2">Personality and Culture</h2>
                <h2 class="font-semibold text-2">Congtivite ability</h2>
                <h2 class="font-semibold text-2">Languages</h2>
                <h2 class="font-semibold text-2">Accounting and Finance</h2>
                <h2 class="font-semibold text-2">Customer Service</h2>
                <h2 class="font-semibold text-2">Admin and Office</h2>
                <h2 class="font-semibold text-2">General Skills</h2>
                <h2 class="font-semibold text-2">Human Resources</h2>
                <h2 class="font-semibold text-2">Marketing</h2>
                <h2 class="font-semibold text-2">Product</h2>
                <h2 class="font-semibold text-2">Sales</h2>
                <h2 class="font-semibold text-2">Software Development</h2> -->
        </div>

        <div class="search-container">
            <i class="fas fa-search search-icon"></i>
            <input type="text" v-model="query" class="search-input" placeholder="Recruiter..." />
        </div>

        <div class="flex flex-wrap mt-10 mb-10">
            <div class="filterContainer flex flex-col p-2.5" ref="filterContainer">
                <div>
                    <h3 class="text-large my-5" style="font-weight: 600; text-align: left">Per category:</h3>
                    <label v-for="(category, index) in categories" :key="index" class="checkbox-label">
                        <input type="checkbox" :value="category" v-model="selectedCategories" class="checkbox-input" />
                        <span class="checkbox-text">{{ category }}</span>
                    </label>
                </div>
            </div>

            <div class="recruiters">
                <MarketPlaceCard v-for="(card, index) in cards" :key="index" :name="card.name" :country="card.country" :desc="card.desc" />
            </div>
        </div>
    </div>
</template>
<script>
import MarketPlaceCard from "@/components/MarketPlaceCard.vue";
export default {
    components: { MarketPlaceCard },

    name: "MarketPlace",
    data() {
        return {
            categories: [
                "All",
                "Information Technology",
                "Software Development",
                // "Healthcare and Medical Services",
                "Sales and Marketing",
                "Education and Training",
                "Finance and Accounting",
                "Engineering",
                "Customer Service and Support",
                "Human Resources",
                "Manufacturing and Production",
                "Management and Leadership",
            ],
            selectedCategories: [],
            cards: [
                {
                    name: "Aymen",
                    country: "Algeria",
                    desc: "I a Recruiter with 10 years of experience in full-cycle recruiting. I hmave worked with well-known companies, startups and have placed candidates in different countries. ",
                },
                { name: "Ali", country: "UAE", desc: "sqflqmflsqmlfùsq kfpeg^gp^segpegkêskgs" },
            ],
        };
    },
};
</script>
<style lang="scss" scoped>
#id {
    background-color: white;
}
.text-3 {
    color: black;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
}

.library-container {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    padding-top: 4rem;
}
.search-container {
    position: relative;
    width: 500px;
    margin-top: 40px;
    margin-left: 40px;
}

.search-input {
    width: 100%;
    padding: 10px 15px; /* Adjust padding to make space for the icon */
    padding-left: 40px; /* Extra space on the left for the icon */
    border-radius: 20px;
    border: 1px solid #ccc;
    font-size: 16px;
    outline: none;
}

.search-icon {
    position: absolute;
    top: 50%;
    left: 15px; /* Position icon on the left */
    transform: translateY(-50%);
    font-size: 20px;
    color: #999;
    pointer-events: none; /* Prevents icon from blocking input focus */
}

.checkbox-label {
    display: flex;
    align-items: center;
    margin-right: 20px;
    /* Adjust spacing between checkboxes */
    margin-bottom: 10px;
}
.checkbox-text {
    margin-left: 5px;
    /* Adjust spacing between checkbox and label */
    font-size: 14px;
    font-weight: 400;
}
.checkbox-input {
    margin-right: 5px;
    /* Adjust spacing between checkbox and label */
    background-color: #2196f3;
}
.filterContainer {
    float: right;
    width: 23%;
    padding-left: 3%;
}

.recruiter {
    word-wrap: break-word;
    width: 100%;
    margin-bottom: 50px;
    margin-top: 50px;
    cursor: pointer;
}

.information {
    flex-direction: column;
    display: flex;
    font-size: 17px;
}

.text {
    color: #676767;
    font-size: 17px;
}

.text-2 {
    background-color: #e9e9e9;
    color: #676767;
    padding: 5px 15px;
    border-radius: 16px;
    font-size: 14px;
}

.desc {
    margin-top: 20px;
    color: #676767;
    font-size: 16px;
    text-align: left;
}
.recruiters {
    width: 70%;
    padding-left: 20px;
    padding-right: 20px;
}
.view-profile {
    padding: 10px 24px;
    color: #2196f3;
    height: fit-content;
    border-radius: 11px;
    border: 2px solid #2196f3;
}

@media only screen and (max-width: 720px) {
    .filterContainer {
        float: right;
        width: 100%;
        padding-left: 3%;
    }
    .recruiters {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
    .search-container {
        position: relative;
        width: 90%;
        margin-top: 40px;
        margin-left: 40px;
    }
}
</style>
