<template>
    <div style="position: relative">
        <div class="soon">Comming Soon.</div>
        <div class="grid-row-1">
            <div class="try-go-platform">
                <div class="try-text">
                    <h1>Try GO Platform for free now!</h1>
                    <p>Complete your recruitment process with GO PLATFORM, Save time and money. 14 Days Free!</p>
                    <div class="button-group">
                        <button>Try For Free</button>
                        <button class="skip">Skip</button>
                    </div>
                </div>
                <div class="try-image">
                    <img loading="lazy" decoding="async" src="" alt="" />
                </div>
            </div>
            <div class="security-section">
                <div class="finger-print">
                    <img loading="lazy" decoding="async" src="@/assets/Images/icons/fingerprint.svg" />
                </div>
                <h1>Keep you safe!</h1>
                <p>Update your security password, keep your account safe!</p>
                <button @click="showPopup = true">Update your security</button>
            </div>
            <div v-if="showPopup">
                <div class="popup-mask" @click="showPopup = false"></div>
                <div class="popup-wrapper">
                    <SecuritySettingsView />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SecuritySettingsView from "./SecuritySettingsView.vue";

export default {
    name: "SecurityView",
    components: { SecuritySettingsView },
    data: function () {
        return {
            showPopup: false,
        };
    },
};
</script>

<style scoped>
.soon {
    z-index: 20;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0000008c;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    color: #fff;
}
.grid-row-1 {
    position: relative;
    display: grid;
    grid-template-columns: 7fr 3fr;
    gap: 16px;
    padding: 24px;
}

.grid-row-1 > div {
    background: #fff;
    padding: 16px;
    border-radius: 20px;
}

.try-go-platform {
    display: flex;
    justify-content: space-between;
}
.try-text {
    margin-left: 16px;
    margin-right: 48px;
}
.try-image {
    background: url(@/assets/Images/try-image.jpg);
    height: 100%;
    width: 100%;
    border-radius: 15px;
}

h1 {
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 34px;
    color: #1b2559;
}

p {
    font-family: "DM Sans";
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #a3aed0;
}

button {
    background: #2196f3;
    border-radius: 70px;
    padding: 16px 24px;
    border: none;
    color: #fff;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}

.skip {
    color: #a3aed0;
    background: none;
}

.security-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-between;
}

.security-section > h1 {
    font-size: 20px;
}

.security-section > p {
    font-size: 14px;
}

.finger-print {
    padding: 16px;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.12);
}
.popup-mask {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5) !important;
    transition: opacity 0.3s ease;
}

.popup-wrapper {
    z-index: 11;
    background: #fff;
    margin: 30px auto 0;
    padding: 16px;
    border-radius: 20px;
    position: absolute;
    width: 40vw;
    top: 50%;
    left: 50%;
    margin-left: -20vw;
    margin-top: -20vw;
}
</style>
